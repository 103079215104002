export default class ChannelTracker {
    constructor(analyticsClass) {
        this.analytics = analyticsClass;
    }

    initialize() {
        // Check whether this document has a channel set in the URL
        const url = new URL(window.location);
        let channel = null;
        let channelGroup = null;

        if (url.searchParams.has('channel')) {
            const channels = url.searchParams.getAll('channel');

            // Use last channel in the list
            channel = channels[channels.length - 1];

            if (channel.length === 0) {
                channel = null;

                // Try resolving other channels until we find one that is not null:
                for (let i = channels.length - 1; i >= 0; i--) {
                    if (channels[i].length > 0) {
                        channel = channels[i];
                        break;
                    }
                }
            }
        }

        if (channel == null) {
            // No channel, re-use existing one:
            channel = this.analytics.retrieveTrackingVar('channel', null);
            channelGroup = this.analytics.retrieveTrackingVar('channelGroup', null);
        }

        // Check if we have a referrer to set:
        if (channel == null && document.referrer !== null && document.referrer !== '') {
            const referrerUrl = new URL(document.referrer);

            if (referrerUrl.hostname !== document.location.hostname && referrerUrl.hostname !== 'keuze.nl' && referrerUrl.hostname !== 'www.keuze.nl') {
                channel = `r_${referrerUrl.hostname}`;

                // Rewrite some known search engines to organic:
                if (channel === 'r_www.google.nl' || channel === 'r_www.google.com' || channel === 'r_www.google.be' || channel === 'r_www.google.de' || channel === 'r_www.google.fr' || channel === 'r_www.google.co.uk') {
                    channel = 'organic-google';
                    channelGroup = 'organic';
                } else if (channel === 'r_www.bing.com') {
                    channel = 'organic-bing';
                    channelGroup = 'organic';
                } else if (channel === 'r_www.ecosia.org') {
                    channel = 'organic-ecosia';
                    channelGroup = 'organic';
                } else if (channel === 'r_duckduckgo.com') {
                    channel = 'organic-duckduckgo';
                    channelGroup = 'organic';
                } else if (channel === 'r_chatgpt.com') {
                    channel = 'organic-chatgpt';
                    channelGroup = 'organic';
                }
            }
        }

        if (url.searchParams.has('utm_source') && url.searchParams.get('utm_source') === 'chatgpt.com') {
            channel = 'organic-chatgpt';
            channelGroup = 'organic';
        }

        if (channel !== null && channel.length > 0) {
            this.analytics.log('Channel now set to', channel, channelGroup);
            this.analytics.setChannel(channel, channelGroup);

            // Store in local storage
            this.saveChannelToStorage(channel, channelGroup);
        } else {
            // No channel, re-use existing one:
            channel = this.analytics.retrieveTrackingVar('channel', null);
            channelGroup = this.analytics.retrieveTrackingVar('channelGroup', null);

            if (channel != null) {
                this.analytics.log('Re-using channel which was set to', channel, channelGroup);
                this.analytics.setChannel(channel, channelGroup);
            }
        }

        if (channel === null) {
            this.analytics.log('No channel known');
        }
    }

    /**
     * Save channel to local storage or cookie
     * @param channel
     * @param channelGroup
     */
    saveChannelToStorage(channel, channelGroup) {
        this.analytics.storeTrackingVar('channel', channel);
        this.analytics.storeTrackingVar('channelGroup', channelGroup);
    }
}
